/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { animated, useSpring } from "@react-spring/web";
import { colors, textStyle } from "../../styles";

export const WorkoutFinished: React.FC = () => {
  const props = useSpring({
    loop: true,
    to: [
      { opacity: 1, color: colors.persianGreen },
      { opacity: 0.2, color: colors.lightGrey },
    ],
    from: { opacity: 0.1, color: colors.burntSienna },
  });

  const styles = {
    container: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    `,
  };

  return (
    <animated.div style={props} css={styles.container}>
      <h1 css={textStyle.h1}>You did it</h1>
      <p css={[textStyle.large, textStyle.bold]}>Well done!</p>
    </animated.div>
  );
};
