/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { useDispatch } from "react-redux";
import { PageContent } from "../../components/PageContent";
import {
  thunkCreateExercise,
  thunkDeleteExercise,
} from "../../redux/exercises/exercise-actions";
import { ExerciseId, INewExercise } from "../../redux/exercises/exercise-types";
import { thunkCreateWorkout } from "../../redux/workouts/workouts-actions";
import { INewWorkout } from "../../redux/workouts/workouts-types";
import { ExerciseList } from "./ExerciseList";
import { ExerciseModal } from "./ExerciseModal";
import { WorkoutList } from "./WorkoutList";
import { WorkoutModal } from "./WorkoutModal";

export const AdminPage: React.FC = () => {
  const dispatch = useDispatch();

  const styles = {
    container: css`
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
    `,
  };

  const handleCreateExercise = (data: INewExercise) => {
    dispatch(thunkCreateExercise(data));
  };

  const handleDeleteExercise = (id: ExerciseId) => {
    dispatch(thunkDeleteExercise(id));
  };

  const handleCreateWorkout = (data: INewWorkout) => {
    dispatch(thunkCreateWorkout(data));
  };

  return (
    <PageContent
      title="Admin tools"
      subTitle={"This is the place of all admin magic"}
    >
      <div css={styles.container}>
        <ExerciseModal onSubmit={handleCreateExercise} />
        <ExerciseList onDelete={handleDeleteExercise} />
        <WorkoutModal onSubmit={handleCreateWorkout} />
        <WorkoutList />
      </div>
    </PageContent>
  );
};
