/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { IRunningWorkoutProgress } from "../../redux/running-workout/running-workout-types";
import { LabelAndValue } from "../../components/LabelAndValue";

interface IProps {
  runningWorkoutProgress: IRunningWorkoutProgress;
}

export const WorkoutProgressInfo: React.FC<IProps> = ({
  runningWorkoutProgress: {
    currentWorkoutSetIndex,
    workoutSetsCount,
    nextWorkoutSet,
  },
}) => {
  const styles = {
    container: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    `,
  };
  return (
    <div css={styles.container}>
      <LabelAndValue
        label="Sets left"
        value={`${currentWorkoutSetIndex}/${workoutSetsCount}`}
      />

      {!!nextWorkoutSet && (
        <LabelAndValue
          isFlexEnd={true}
          label="Next:"
          value={`${nextWorkoutSet.name} (${nextWorkoutSet.durationInSeconds} s)`}
        />
      )}

      {!nextWorkoutSet && (
        <LabelAndValue
          isFlexEnd={true}
          label="Keep going"
          value="Almost there"
        />
      )}
    </div>
  );
};
