import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import { requestLogin } from "../../api/auth-api";
import { AppState } from "../store";
import { actionCreator } from "../util";
import { storeToken } from "./auth-store";
import { ILoginCredentials, IUserAuth, UserRole } from "./auth-types";

export const login = actionCreator.async<ILoginCredentials, IUserAuth, string>(
  "LOGIN"
);

export const thunkLogin =
  (
    params: ILoginCredentials
  ): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(login.started(params));
      const { token } = await requestLogin(params);
      const result: IUserAuth = jwt_decode(token);
      if (result.role !== UserRole.Admin) {
        console.error("Login permitted for admin only");
        throw new Error("Login permitted for admin only");
      }
      storeToken(token);
      dispatch(login.done({ params, result }));
    } catch (e: any) {
      dispatch(login.failed({ params, error: e.toString() }));
    }
  };
