/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react/macro";
import { ProgressIndicator } from "@fluentui/react";
import { a, useSpring } from "@react-spring/web";
import { ICurrentWorkoutSet } from "../../redux/running-workout/running-workout-types";
import { colors, mediaMaxWidth, resetBtnStyles } from "../../styles";

interface IProps {
  workout: ICurrentWorkoutSet;
  paused: boolean;
  onClick: () => void;
}

const bounce = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const CurrentWorkoutItem: React.FC<IProps> = ({
  workout,
  paused,
  onClick,
}) => {
  const styles = {
    container: {
      common: css`
        animation: 1s ease-out 0s 1 ${bounce};
        display: flex;
        flex-direction: column;
        height: 50px;
        color: black;
        ${resetBtnStyles}

        .ms-ProgressIndicator-progressBar {
          background-color: black;
        }
      `,

      paused: css`
        .ms-ProgressIndicator-progressBar {
          background-color: ${colors.lightGrey};
        }
      `,
    },
    body: css`
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    `,
    img: {
      common: css`
        min-height: 300px;
        min-width: 500px;
        height: 300px;
        width: 100%;
        position: relative;
        object-fit: cover;

        ${mediaMaxWidth(500)} {
          max-width: 100%;
          min-height: 250px;
          height: 0px;
          min-width: initial;
        }
      `,
    },
    imageLayout: css`
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 10px;
      display: flex;
      gap: 5px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    workoutName: css`
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
    `,
    workoutDescription: css`
      text-transform: uppercase;
      font-size: 10px;
    `,
    secondsLeft: css`
      position: absolute;
      width: 25px;
      height: 25px;
      border: 1px solid white;
      color: white;
      border-radius: 50%;
      font-size: 10px;
      font-weight: bold;
      right: 10px;
      top: auto;
      botton: auto;

      display: flex;
      align-items: center;
      justify-content: center;
    `,
    footer: css`
      display: flex;
      width: 100%;
      align-items: flex-end;
      flex: 1;

      > .ms-ProgressIndicator {
        width: 100%;
      }
    `,
  };

  const { x } = useSpring({
    from: { x: 0 },
    x: !paused ? 1 : 0,
    config: { duration: 500 },
  });

  return (
    <a.button
      onClick={onClick}
      style={{
        opacity: x.to({ range: [0, 1], output: [0.3, 1] }),
        scale: x.to({
          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
          output: [1, 0.99, 0.98, 0.97, 0.98, 0.99, 1, 1],
        }),
      }}
      css={[styles.container.common, paused && styles.container.paused]}
    >
      <div css={styles.body}>
        <img
          css={[styles.img.common]}
          src={workout.imgSrc}
          alt={workout.name}
        />
        <div css={styles.imageLayout}>
          <div css={styles.workoutName}>{workout.name}</div>
          <div css={styles.workoutDescription}>{workout.description}</div>
          <div css={styles.secondsLeft}>{workout.secondsLeft}</div>
        </div>
      </div>

      <div css={styles.footer}>
        <ProgressIndicator
          percentComplete={workout.progressPercentage}
          barHeight={4}
        />
      </div>
    </a.button>
  );
};
