import { compose, find, mapAccum, prop, sum } from "ramda";
import { AppState } from "../store";
import {
  ICurrentWorkoutSet,
  IRunningWorkoutProgress,
  IWorkoutSet,
} from "./running-workout-types";

export const selectRunningWorkoutId = (state: AppState) =>
  state.runningWorkout.currentWorkoutId;

export const selectRunningWorkout = (state: AppState) => {
  const currentWorkoutId = selectRunningWorkoutId(state);
  return currentWorkoutId
    ? state.workouts.workouts[currentWorkoutId]
    : undefined;
};

export const selectRunningWorkoutName = (state: AppState) =>
  selectRunningWorkout(state)?.name;

export const selectRunningWorkoutSets = (state: AppState) =>
  selectRunningWorkout(state)?.sets;

export const selectRunningWorkoutStartTime = (state: AppState) =>
  state.runningWorkout.startTime;

export const selectRunningWorkoutStatus = (state: AppState) =>
  state.runningWorkout.status;

export const selectRunninWorkoutSetDurationInSeconds = (state: AppState) =>
  state.runningWorkout.workoutSetDurationInSeconds || 0;

export const selectRunningWorkoutTargetDurationInSeconds = (state: AppState) =>
  compose<IWorkoutSet[], number[], number>(sum, (workouts) =>
    workouts.map(prop("durationInSeconds"))
  )(selectRunningWorkoutSets(state) || []);

export const selectEnrichedWorkouts = (state: AppState) =>
  mapAccum<
    IWorkoutSet,
    number,
    IWorkoutSet & { startSeconds: number; endSenconds: number }
  >(
    (acc, val) => {
      const startSeconds = acc;
      const endSenconds = acc + val.durationInSeconds;
      return [endSenconds, { ...val, startSeconds, endSenconds }];
    },
    0,
    selectRunningWorkoutSets(state) || []
  )[1];

export const selectCurrentWorkout = (
  state: AppState
): ICurrentWorkoutSet | undefined => {
  const workouts = selectEnrichedWorkouts(state);
  const currentSeconds = state.runningWorkout.workoutSetDurationInSeconds ?? 0;
  const workout = find(
    (workout) =>
      workout.startSeconds <= currentSeconds &&
      workout.endSenconds > currentSeconds,
    workouts
  );
  return workout
    ? {
        ...workout,
        secondsLeft: workout.endSenconds - currentSeconds,
        progressPercentage:
          (workout.durationInSeconds - (workout.endSenconds - currentSeconds)) /
          workout.durationInSeconds,
      }
    : undefined;
};

export const selectRunningWorkoutInfo = (
  state: AppState
): IRunningWorkoutProgress => {
  const workouts = selectEnrichedWorkouts(state);
  const currentSeconds = state.runningWorkout.workoutSetDurationInSeconds ?? 0;
  const currentWorkoutSetIndex = workouts.findIndex(
    (workout) =>
      workout.startSeconds <= currentSeconds &&
      workout.endSenconds > currentSeconds
  );
  return {
    currentWorkoutSetIndex: currentWorkoutSetIndex + 1,
    workoutSetsCount: selectRunningWorkoutSets(state)?.length || 0,
    workoutStatus: state.runningWorkout.status,
    nextWorkoutSet:
      workouts.length > currentWorkoutSetIndex + 1
        ? workouts[currentWorkoutSetIndex + 1]
        : undefined,
  };
};
