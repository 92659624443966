import { IRunninWorkoutState } from "./running-workout/running-workout-types";
import { runningWorkoutReducer } from "./running-workout/running-workout-reducers";
import { configureStore } from "@reduxjs/toolkit";
import { workoutsReducer } from "./workouts/workouts-reducers";
import { exercisesReducer } from "./exercises/exercise-reducers";
import { authReducer } from "./auth/auth-reducers";

export interface IAppState {
  runningWorkout: IRunninWorkoutState;
}

export const store = configureStore({
  reducer: {
    runningWorkout: runningWorkoutReducer,
    workouts: workoutsReducer,
    exercises: exercisesReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
