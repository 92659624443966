/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { TextField } from "@fluentui/react";
import { Field, FieldProps } from "formik";
import { colors } from "../../styles";

interface ITextInputProps {
  name: string;
  label: string;
  placeholder: string;
  inputType?: string;
}

export const TextInput: React.FC<ITextInputProps> = ({
  name,
  label,
  placeholder,
  inputType,
}) => {
  const styles = {
    container: css`
      gap: 5px;
      display: flex;
      flex-direction: column;
    `,
    label: css`
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
    `,
    error: css`
      color: ${colors.burntSienna};
    `,
  };
  return (
    <Field id={name} name={name} placeholder={placeholder}>
      {({ field, form: { touched, errors }, meta }: FieldProps) => (
        <div css={styles.container}>
          <TextField
            label={label}
            placeholder={placeholder}
            {...field}
            type={inputType || "text"}
          />
          {meta.touched && meta.error && (
            <div css={styles.error}>{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};
