import { indexBy, prop } from "ramda";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { getWorkouts, setCurrentWorkout } from "./workouts-actions";
import { IWorkout, IWorkoutsState, WorkoutId } from "./workouts-types";

const initialState: IWorkoutsState = {
  activeRequests: 0,
  workouts: {},
};

const reduceActiveRequest = (activeRequests: number): number =>
  activeRequests > 0 ? activeRequests - 1 : 0;

export const workoutsReducer = reducerWithInitialState(initialState)
  .case(setCurrentWorkout, (state, payload) => ({
    ...state,
    currentWorkoutId: payload
  }))
  .case(getWorkouts.started, (state) => ({
    ...state,
    activeRequests: state.activeRequests + 1,
  }))
  .case(getWorkouts.done, (state, { result }) => ({
    ...state,
    workouts: indexBy<IWorkout, WorkoutId>(prop("id"))(result),
    activeRequests: reduceActiveRequest(state.activeRequests),
  }))
  .case(getWorkouts.failed, (state) => ({
    ...state,
    activeRequests: reduceActiveRequest(state.activeRequests),
  }));
