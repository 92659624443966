import { getStoredToken } from "../redux/auth/auth-store";

export const baseUrl = "https://rainer-api.hopto.org";

const getAuthHeader = () => {
  const token = getStoredToken();
  return !token
    ? undefined
    : {
        Authorization: `Token ${token}`,
      };
};

export const get = async (path: string) =>
  fetch(`${baseUrl}${path}`, {
    method: "GET",
    headers: {
      "content-type": "application/json;charset=UTF-8",
      ...getAuthHeader(),
    },
  }).then((res) => res.json());

export const del = async (path: string) =>
  fetch(`${baseUrl}${path}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json;charset=UTF-8",
      ...getAuthHeader(),
    },
  });

export const post = async (path: string, data: any) =>
  fetch(`${baseUrl}${path}`, {
    method: "POST",
    headers: {
      "content-type": "application/json;charset=UTF-8",
      ...getAuthHeader(),
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());

export const put = async (path: string, data: any) =>
  fetch(`${baseUrl}${path}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json;charset=UTF-8",
      ...getAuthHeader(),
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
