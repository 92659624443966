/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import {
  Checkbox,
  CheckboxVisibility,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  Spinner,
} from "@fluentui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  thunkDeleteExercise,
  thunkGetExercises,
} from "../../redux/exercises/exercise-actions";
import {
  selectExercises,
  selectIsLoadingExercises,
} from "../../redux/exercises/exercise-selectors";
import { ExerciseId, IExercise } from "../../redux/exercises/exercise-types";

const columns: IColumn[] = [
  {
    key: "isPublic",
    name: "Public",
    fieldName: "userId",
    minWidth: 50,
    maxWidth: 50,
    isResizable: false,
    onRender: (item) => (
      <Checkbox checked={!item.userId} label="" onChange={() => {}} />
    ),
  },
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "description",
    name: "Description",
    fieldName: "description",
    minWidth: 100,
    isResizable: true,
  },
  {
    key: "unit",
    name: "Unit",
    fieldName: "unit",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: "type",
    name: "Type",
    fieldName: "type",
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
  },
  {
    key: "actions",
    name: "Actions",
    fieldName: "actions",
    minWidth: 50,
    isResizable: false,
  },
];

interface IExerciseListProps {
  onDelete: (id: ExerciseId) => void;
}

export const ExerciseList: React.FC<IExerciseListProps> = () => {
  const dispatch = useDispatch();
  const exercises = useSelector(selectExercises);
  const loading = useSelector(selectIsLoadingExercises);

  useEffect(() => {
    dispatch(thunkGetExercises());
  }, [dispatch]);

  const styles = {
    container: css`
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    `,
    tableColumn: css`
      height: 100%;
      display: flex;
      align-items: center;
    `,
  };

  const handleDeleteExercise = (id: number) => {
    if (window.confirm("Do you want to delete exercise?")) {
      dispatch(thunkDeleteExercise(id));
    }
  };

  const onRenderItemColumn = (
    item: any,
    _: number | undefined,
    column: IColumn | undefined
  ) => {
    const fieldContent = item[column?.fieldName as keyof IExercise] as string;
    switch (column?.key) {
      case "actions":
        return (
          <IconButton
            iconProps={{ iconName: "Delete" }}
            title="Delete exercise"
            ariaLabel="Delete exercise"
            onClick={() => handleDeleteExercise(item?.id)}
          />
        );

      default:
        return <div css={styles.tableColumn}>{fieldContent}</div>;
    }
  };

  return (
    <div css={styles.container}>
      {loading && <Spinner />}
      {exercises && (
        <DetailsList
          items={exercises}
          columns={columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="select row"
          onRenderItemColumn={onRenderItemColumn}
          checkboxVisibility={CheckboxVisibility.hidden}
        />
      )}
    </div>
  );
};
