export interface ILoginCredentials {
  username: string;
  password: string;
}

export enum UserRole {
  Admin = "ADMIN",
  User = "USER",
}

export interface IUserAuth {
  sub: string;
  role: UserRole;
  exp: number;
}

export interface IAuthState {
  activeRequests: number;
  auth?: IUserAuth;
}
