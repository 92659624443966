import { AnyAction, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { actionCreator } from "../util";
import { WorkoutId } from "../workouts/workouts-types";
import {
  selectCurrentWorkout,
  selectRunningWorkoutTargetDurationInSeconds,
  selectRunninWorkoutSetDurationInSeconds,
} from "./running-workout-selectors";
import { RunningWorkoutStatus } from "./running-workout-types";

export const selectWorkoutId = actionCreator<WorkoutId>("SELECT_WORKOUT_ID");
export const startWorkout =
  actionCreator<{ startTime: Date; intervalId: NodeJS.Timeout }>(
    "START_WORKOUT"
  );
export const stopWorkout = actionCreator<void>("STOP_WORKOUT");
export const pauseWorkout = actionCreator<void>("PAUSE_WORKOUT");
export const finishWorkout = actionCreator<void>("FINISH_WORKOUT");

export const updateWorkout =
  actionCreator<{ workoutDurationInSeconds: number }>("UPDATE_WORKOUT");

export const thunkSelectWorkoutId =
  (workoutId: number): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(selectWorkoutId(workoutId));
  };

export const thunkStartWorkout =
  (): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const startTime = new Date();
    const intervalId = startWorkoutRunner(startTime, dispatch, getState);
    dispatch(startWorkout({ startTime, intervalId }));
  };

export const thunkStopWorkout =
  (): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    clearWorkoutInterval(getState());
    dispatch(stopWorkout());
  };

export const thunkFinishWorkout =
  (): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    clearWorkoutInterval(getState());
    dispatch(finishWorkout());
  };

export const thunkSkipWorkoutSet =
  (): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const state = getState();
    const duration = selectRunninWorkoutSetDurationInSeconds(state);
    const currentSet = selectCurrentWorkout(state);
    const workoutDurationInSeconds = duration + (currentSet?.secondsLeft || 0);
    dispatch(updateWorkout({ workoutDurationInSeconds }));
  };

const clearWorkoutInterval = (state: AppState) => {
  const intervalId = state.runningWorkout.intervalId;
  if (intervalId) {
    clearInterval(intervalId);
  }
};

const startWorkoutRunner = (
  startTime: Date,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  getState: () => AppState
) =>
  setInterval(() => {
    const state = getState();

    if (state.runningWorkout.status === RunningWorkoutStatus.Paused) {
      return;
    }

    if (
      state.runningWorkout.workoutSetDurationInSeconds &&
      state.runningWorkout.workoutSetDurationInSeconds >=
        selectRunningWorkoutTargetDurationInSeconds(state)
    ) {
      dispatch(thunkFinishWorkout());
      return;
    }

    const workoutDurationInSeconds =
      (state.runningWorkout.workoutSetDurationInSeconds || 0) + 1;

    dispatch(updateWorkout({ workoutDurationInSeconds }));
  }, 1000);
