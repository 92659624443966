/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { ProgressIndicator } from "@fluentui/react";
import { PageContent } from "../components/PageContent";
import { textStyle } from "../styles";

interface IProps {}

export const AboutPage: React.FC<IProps> = () => {
  const styles = {
    container: css``,
  };

  return (
    <PageContent title="About page">
      <div css={styles.container}>
        <h2 css={textStyle.h2}>The Author</h2>
        <ProgressIndicator label="Odottellaanpa..." />
      </div>
    </PageContent>
  );
};
