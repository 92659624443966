import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  finishWorkout,
  pauseWorkout,
  selectWorkoutId,
  startWorkout,
  stopWorkout,
  updateWorkout,
} from "./running-workout-actions";
import {
  IRunninWorkoutState,
  RunningWorkoutStatus,
} from "./running-workout-types";

const initialState: IRunninWorkoutState = {
  status: RunningWorkoutStatus.Initial,
  startTime: undefined,
  intervalId: undefined,
  currentWorkoutItemId: undefined,
  workoutSetDurationInSeconds: undefined,
  currentWorkoutId: undefined,
};

export const runningWorkoutReducer = reducerWithInitialState(initialState)
  .case(selectWorkoutId, (_, payload) => ({
    ...initialState,
    currentWorkoutId: payload,
  }))
  .case(startWorkout, (state, { startTime, intervalId }) => ({
    ...state,
    startTime: startTime.toISOString(),
    intervalId,
    workoutSetDurationInSeconds: 0,
    status: RunningWorkoutStatus.Running,
  }))
  .case(pauseWorkout, (state) => ({
    ...state,
    status:
      state.status === RunningWorkoutStatus.Paused
        ? RunningWorkoutStatus.Running
        : RunningWorkoutStatus.Paused,
  }))
  .case(stopWorkout, (state) => ({
    ...state,
    currentWorkoutId: undefined,
    startTime: undefined,
    intervalId: undefined,
    status: RunningWorkoutStatus.Initial,
  }))
  .case(finishWorkout, (state) => ({
    ...state,
    status: RunningWorkoutStatus.Finished,
  }))
  .case(updateWorkout, (state, { workoutDurationInSeconds }) => ({
    ...state,
    workoutSetDurationInSeconds: workoutDurationInSeconds,
  }));
