import { colors } from "../../styles";

export type WorkoutItemId = number;
export type WorkoutId = number;

export enum ExerciseType {
  Countdown = "countdown",
  Rest = "REST",
  Abs = "ABS",
}

export interface IWorkoutSet {
  id: WorkoutItemId;
  name: string;
  description?: string;
  durationInSeconds: number;
  type: ExerciseType;
  imgSrc: string;
}

export enum Level {
  Beginner = "Beginner",
  Intermediate = "Intermediate",
  Expert = "Expert",
}

export const LevelColors = {
  [Level.Beginner]: colors.persianGreen,
  [Level.Intermediate]: colors.orangeYellowCrayola,
  [Level.Expert]: colors.burntSienna,
};

export interface IWorkout {
  id: WorkoutId;
  level: Level;
  name: string;
  description: string;
  sets: IWorkoutSet[];
}

export interface INewWorkout {
  name: string;
  description: string;
  public: boolean;
  sets: any[];
}

export interface IWorkoutsState {
  activeRequests: number;
  workouts: Record<WorkoutId, IWorkout>;
  currentWorkoutId?: number;
}
