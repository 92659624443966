/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { FormikProps } from "formik";
import { FormEvent } from "react";

interface IFormContainerProps extends FormikProps<any> {
  children: React.ReactNode;
  onCancel?: () => void;
}

export const FormContainer: React.FC<IFormContainerProps> = ({
  children,
  isValid,
  onCancel,
  submitForm,
}) => {
  const styles = {
    container: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
    `,
    buttonContainer: css`
      display: flex;
      gap: 10px;
      margin-top: 20px;
      width: 100%;
    `,
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    submitForm();
  };

  return (
    <form css={styles.container} onSubmit={handleSubmit}>
      {children}
      <div css={styles.buttonContainer}>
        <PrimaryButton
          text="Submit"
          allowDisabledFocus
          disabled={!isValid}
          type="submit"
        />
        {onCancel && (
          <DefaultButton
            text="Cancel"
            allowDisabledFocus
            onClick={onCancel}
            type="button"
          />
        )}
      </div>
    </form>
  );
};
