import { ICurrentWorkoutSet } from "../../redux/running-workout/running-workout-types";
import { Howl } from "howler";
import { ExerciseType } from "../../redux/workouts/workouts-types";

enum SoundEffects {
  SimpleBeep = "beep.mp3",
  CountDown = "countdown.mp3",
  Applause = "applause.mp3",
  Startup = "startup.mp3",
}

export const playSoundEffect = (soundEffect: SoundEffects) => {
  const sound = new Howl({
    src: [soundEffect],
  });
  sound.play();
  return sound;
};

export const getSoundEffect = ({
  wholeWorkoutDurationInSeconds,
  currentWorkoutSet,
  alreadySpokeSeconds,
}: {
  wholeWorkoutDurationInSeconds: number;
  currentWorkoutSet: ICurrentWorkoutSet | undefined;
  alreadySpokeSeconds: Set<number>;
}): SoundEffects | undefined => {
  const currentWorkoutSecondsLeft = currentWorkoutSet?.secondsLeft;
  const currentWorkoutDurationInSeconds = currentWorkoutSet?.durationInSeconds;
  const newWorkoutStarted =
    currentWorkoutSet?.secondsLeft === currentWorkoutSet?.durationInSeconds;

  if (
    !currentWorkoutSet ||
    currentWorkoutDurationInSeconds === undefined ||
    currentWorkoutSecondsLeft === undefined ||
    wholeWorkoutDurationInSeconds === undefined ||
    alreadySpokeSeconds.has(wholeWorkoutDurationInSeconds)
  ) {
    return undefined;
  }

  const isHalfWay =
    currentWorkoutSet.durationInSeconds > 10 &&
    currentWorkoutSet.secondsLeft ===
      Math.floor(currentWorkoutSet.durationInSeconds / 2);

  if (
    wholeWorkoutDurationInSeconds === 0 &&
    currentWorkoutSet.type === ExerciseType.Countdown
  ) {
    return SoundEffects.CountDown;
  } else if (newWorkoutStarted) {
    if (currentWorkoutSet?.type === ExerciseType.Rest) {
      return SoundEffects.Applause;
    } else {
      return SoundEffects.Startup;
    }
  } else if (isHalfWay) {
    return SoundEffects.SimpleBeep;
  } else if (
    currentWorkoutSecondsLeft === 30 &&
    currentWorkoutDurationInSeconds > 40
  ) {
    return SoundEffects.SimpleBeep;
  } else if (
    (currentWorkoutSecondsLeft === 3 ||
      currentWorkoutSecondsLeft === 2 ||
      currentWorkoutSecondsLeft === 1) &&
    currentWorkoutSet.type !== ExerciseType.Countdown
  ) {
    return SoundEffects.SimpleBeep;
  }
};
