import { AppState } from "../store";

export const selectIsLoadingWorkouts = (state: AppState) =>
  state.workouts.activeRequests > 0;

export const selectWorkouts = (state: AppState) => state.workouts.workouts;

export const selectCurrentWorkout = (state: AppState) => {
  const id = state.workouts.currentWorkoutId;
  return id ? state.workouts.workouts[id] : undefined;
};
