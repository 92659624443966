/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { Spinner } from "@fluentui/react";
import { Howl } from "howler";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ErrorMessage } from "../../components/ErrorMessage";
import { PageContent } from "../../components/PageContent";
import {
  pauseWorkout,
  thunkSelectWorkoutId,
  thunkSkipWorkoutSet,
  thunkStartWorkout,
  thunkStopWorkout,
} from "../../redux/running-workout/running-workout-actions";
import {
  selectCurrentWorkout,
  selectRunningWorkout,
  selectRunningWorkoutInfo as selectRunningWorkoutProgress,
  selectRunningWorkoutStartTime,
  selectRunningWorkoutStatus,
  selectRunninWorkoutSetDurationInSeconds,
} from "../../redux/running-workout/running-workout-selectors";
import { RunningWorkoutStatus } from "../../redux/running-workout/running-workout-types";
import { thunkGetworkout } from "../../redux/workouts/workouts-actions";
import { selectIsLoadingWorkouts } from "../../redux/workouts/workouts-selectors";
import { mediaMaxWidth } from "../../styles";
import { CurrentWorkoutItem } from "./CurrentWorkoutItem";
import { getSoundEffect, playSoundEffect } from "./speaker";
import { WorkoutActionButtons } from "./WorkoutActionButtons";
import { WorkoutFinished } from "./WorkoutFinished";
import { WorkoutProgressInfo } from "./WorkoutProgressInfo";

interface IRunWorkoutPageProps {
  workoutId: number;
}

export const RunWorkoutPage: React.FC<IRunWorkoutPageProps> = ({
  workoutId,
}) => {
  const dispatch = useDispatch();
  const isLoadingWorkouts = useSelector(selectIsLoadingWorkouts);
  const selectedWorkout = useSelector(selectRunningWorkout);
  const startTime = useSelector(selectRunningWorkoutStartTime);
  const wholeWorkoutDurationInSeconds = useSelector(
    selectRunninWorkoutSetDurationInSeconds
  );
  const currentWorkoutSet = useSelector(selectCurrentWorkout);
  const workoutStatus = useSelector(selectRunningWorkoutStatus);
  const runningWorkoutProgress = useSelector(selectRunningWorkoutProgress);
  const history = useHistory();

  const [alreadySpokeSeconds, setAlreadySpeakSeconds] = useState<Set<number>>(
    new Set()
  );

  const [sounds, setSounds] = useState<Howl[]>([]);

  useEffect(() => {
    dispatch(thunkGetworkout(workoutId));
    dispatch(thunkSelectWorkoutId(workoutId));
  }, [dispatch, workoutId]);

  useEffect(() => {
    if (workoutStatus !== RunningWorkoutStatus.Running) {
      return;
    }

    const soundEffectToPlay = getSoundEffect({
      alreadySpokeSeconds,
      currentWorkoutSet,
      wholeWorkoutDurationInSeconds,
    });

    if (!soundEffectToPlay) {
      return;
    }

    setAlreadySpeakSeconds((oldVal) =>
      oldVal.add(wholeWorkoutDurationInSeconds)
    );
    const sound = playSoundEffect(soundEffectToPlay);
    setSounds((sounds) => [...sounds, sound]);
  }, [
    currentWorkoutSet,
    wholeWorkoutDurationInSeconds,
    alreadySpokeSeconds,
    workoutStatus,
  ]);

  const styles = {
    container: css`
      margin: 30px auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
      align-items: center;
      width: 500px;
      ${mediaMaxWidth(500)} {
        width: 100%;
      }
    `,
  };

  const stopAudio = () => {
    sounds.forEach((sound) => sound.stop());
  };

  const handleStartWorkoutBtnClick = () => {
    setAlreadySpeakSeconds(new Set());
    stopAudio();
    dispatch(thunkStartWorkout());
  };

  const handlePauseWorkoutBtnClick = () => {
    stopAudio();
    dispatch(pauseWorkout());
  };

  const handleStopWorkoutBtnClick = () => {
    stopAudio();
    dispatch(thunkStopWorkout());
    history.push("/select-workout");
  };

  const handleSkipWorkoutBtnClick = () => {
    stopAudio();
    dispatch(thunkSkipWorkoutSet());
  };

  return (
    <PageContent title="Run workout" subTitle={selectedWorkout?.name}>
      <div css={styles.container}>
        {!!currentWorkoutSet && startTime && (
          <WorkoutProgressInfo
            runningWorkoutProgress={runningWorkoutProgress}
          />
        )}

        {isLoadingWorkouts && <Spinner label="Just wait..." />}

        {!isLoadingWorkouts && !selectedWorkout && (
          <ErrorMessage error={"Workout not found"} />
        )}

        {!!selectedWorkout && <WorkoutActionButtons
          workoutStatus={workoutStatus}
          onPauseWorkoutBtnClick={handlePauseWorkoutBtnClick}
          onStartWorkoutBtnClick={handleStartWorkoutBtnClick}
          onStopWorkoutBtnClick={handleStopWorkoutBtnClick}
          onSkipWorkoutBtnClick={handleSkipWorkoutBtnClick}
        />}

        {workoutStatus === RunningWorkoutStatus.Finished && <WorkoutFinished />}

        {!!currentWorkoutSet && startTime && (
          <CurrentWorkoutItem
            onClick={handlePauseWorkoutBtnClick}
            workout={currentWorkoutSet}
            paused={workoutStatus === RunningWorkoutStatus.Paused}
          />
        )}
      </div>
    </PageContent>
  );
};
