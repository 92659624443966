import { reducerWithInitialState } from "typescript-fsa-reducers";
import { login } from "./auth-actions";
import { getStoredToken } from "./auth-store";
import { IAuthState, IUserAuth } from "./auth-types";
import jwt_decode from "jwt-decode";

const getInitialAuth = () => {
  const token = getStoredToken();
  if (!!token) {
    const result: IUserAuth = jwt_decode(token);
    return result;
  }
  return undefined;
};

const initialState: IAuthState = {
  activeRequests: 0,
  auth: getInitialAuth(),
};

const reduceActiveRequest = (activeRequests: number): number =>
  activeRequests > 0 ? activeRequests - 1 : 0;

export const authReducer = reducerWithInitialState(initialState)
  .case(login.started, (state) => ({
    ...state,
    activeRequests: state.activeRequests + 1,
  }))
  .case(login.done, (state, { result }) => ({
    ...state,
    auth: result,
    activeRequests: reduceActiveRequest(state.activeRequests),
  }))
  .case(login.failed, (state) => ({
    ...state,
    auth: undefined,
    activeRequests: reduceActiveRequest(state.activeRequests),
  }));
