/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { colors, textStyle } from "../styles";

interface IErrorMessageProps {
  error: string;
}

export const ErrorMessage: React.FC<IErrorMessageProps> = ({ error }) => {
  const styles = {
    container: css`
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
    `,
    errorText: css`
      padding: 0;
      margin: 0;
      color: ${colors.burntSienna};
    `,
  };
  return (
    <div css={styles.container}>
      <h1 css={[textStyle.h1, styles.errorText]}>Error</h1>
      <p css={styles.errorText}>{error}</p>
    </div>
  );
};
