import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import {
  requestCreateRoutine,
  requestDeleteRoutine,
  requestGetRoutine,
  requestGetRoutines,
} from "../../api/routine-api";
import { AppState } from "../store";
import { actionCreator } from "../util";
import { INewWorkout, IWorkout, WorkoutId } from "./workouts-types";

export const setCurrentWorkout = actionCreator<number | undefined>(
  "SET_CURRENT_WORKOUT"
);
export const getWorkouts = actionCreator.async<undefined, IWorkout[], string>(
  "GET_WORKOUTS"
);
export const createWorkout = actionCreator.async<INewWorkout, void, string>(
  "CREATE_WORKOUT"
);
export const deleteWorkout = actionCreator.async<number, void, string>(
  "DELETE_WORKOUT"
);
export const updateWorkout = actionCreator.async<
  { id: WorkoutId; data: INewWorkout },
  void,
  string
>("UPDATE_WORKOUT");

export const thunkGetWorkouts =
  (): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(getWorkouts.started(undefined));
      const data = await requestGetRoutines();
      dispatch(getWorkouts.done({ result: data }));
    } catch (e: any) {
      dispatch(getWorkouts.failed({ error: e.toString() }));
    }
  };

export const thunkGetworkout =
  (workoutId: number): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(getWorkouts.started(undefined));
      const data = await requestGetRoutine(workoutId);
      dispatch(getWorkouts.done({ result: [data] }));
    } catch (e: any) {
      dispatch(getWorkouts.failed({ error: e.toString() }));
    }
  };

export const thunkDeleteWorkout =
  (id: WorkoutId): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(deleteWorkout.started(id));
      await requestDeleteRoutine(id);
      dispatch(deleteWorkout.done({ params: id }));
      dispatch(thunkGetWorkouts());
    } catch (e: any) {
      console.log(e);
      dispatch(deleteWorkout.failed({ params: id, error: e.toString() }));
    }
  };

export const thunkCreateWorkout =
  (params: INewWorkout): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(createWorkout.started(params));
      await requestCreateRoutine(params);
      dispatch(createWorkout.done({ params }));
      dispatch(thunkGetWorkouts());
    } catch (e: any) {
      dispatch(createWorkout.failed({ params, error: e.toString() }));
    }
  };
