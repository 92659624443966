/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { Formik } from "formik";
import { FormContainer } from "../../components/form/FormContainer";
import { TextInput } from "../../components/form/TextInput";
import { ILoginCredentials } from "../../redux/auth/auth-types";
import { textStyle } from "../../styles";
import * as yup from "yup";
import { SchemaOf } from "yup";

interface ILoginFormProps {
  onSubmit: (credentials: ILoginCredentials) => void;
}

const validationSchema: SchemaOf<ILoginCredentials> = yup
  .object({
    username: yup.string().defined(),
    password: yup.string().defined(),
  })
  .defined();

export const LoginForm: React.FC<ILoginFormProps> = ({ onSubmit }) => {
  const styles = {
    container: css`
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 30px 30px 10px 30px;
      width: 500px;
      max-width: 1200px;
      margin: 0 auto;
      box-shadow: 1px 2px 6px #888888;
    `,
    titleContainer: css`
      display: flex;
      flex-direction: column;
      gap: 10px;
    `,
    title: css`
      font-size: 36px;
    `,
    footer: css`
      text-align: end;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
    `,
  };

  return (
    <div css={styles.container}>
      <div css={styles.titleContainer}>
        <h1 css={[textStyle.h1, textStyle.bold, styles.title]}>Log in</h1>
        <h2 css={[textStyle.h2]}>Open some fancy features by logging in</h2>
      </div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          username: "",
          password: "",
        }}
        onSubmit={onSubmit}
      >
        {(props) => (
          <FormContainer {...props}>
            <TextInput
              label="Username"
              placeholder="Username"
              name="username"
            />
            <TextInput
              label="Password"
              placeholder="Password"
              name="password"
              inputType="password"
            />
          </FormContainer>
        )}
      </Formik>
      <p css={styles.footer}>These features are in beta</p>
    </div>
  );
};
