/** @jsxImportSource @emotion/react */
import React from "react";
import {
  IconButton,
  IContextualMenuProps,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { css } from "@emotion/react/macro";
import { useConst } from "@fluentui/react-hooks";
import { mediaMaxWidth } from "../styles";
import { mergeStyles } from "@fluentui/react/lib/Styling";

type NavKey = "home" | "about" | "run-workout";

interface INavItem {
  key: NavKey;
  label: string;
  link: string;
  regex: RegExp;
}

const navItems: INavItem[] = [
  {
    key: "home",
    label: "Home",
    link: "/home",
    regex: new RegExp("^/home$"),
  },
  {
    key: "about",
    label: "About",
    link: "/about",
    regex: new RegExp("^/about$"),
  },
];

const resolveActiveItem = (path: string): NavKey => {
  if (!path) {
    return "home";
  }

  const item = navItems.find((item) => path?.match(item.regex));

  return !!item ? item.key : "home";
};

const iconSize = "30px";

export const Navigation: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const styles = {
    container: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      ${mediaMaxWidth(600)} {
        padding-top: 12px;
        justify-content: space-between;
      }
    `,
    logoContainer: css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `,
    logo: css`
      font-size: 20px;
      padding-right: 10px;
      padding-bottom: 3px;
      margin-right: 10px;
      border-right: 1px solid #333232;
      font-weight: bold;

      ${mediaMaxWidth(600)} {
        border-right: none;
      }
    `,
    icon: css`
      margin: 0 10px;
      font-size: ${iconSize};
      height: ${iconSize};
      width: ${iconSize};
      color: black;
    `,
    desktopNav: css`
      ${mediaMaxWidth(600)} {
        display: none;
      }
    `,
    mobileNav: css`
      display: none;

      ${mediaMaxWidth(600)} {
        display: initial;
      }
    `,
  };

  const menuProps = useConst<IContextualMenuProps>(() => ({
    shouldFocusOnMount: true,
    items: navItems.map((item) => ({
      key: item.key,
      text: item.label,
      onClick: () => handleLinkClick(item.key),
    })),
  }));

  const [selectedKey, setSelectedKey] = useState<string>(
    resolveActiveItem(location.pathname)
  );

  const handleLinkClick = (itemKey?: String) => {
    const item = navItems.find((item) => item.key === itemKey);
    if (!item) {
      return;
    }
    history.push(item.link);
    setSelectedKey(item.key);
  };

  const handlePivotItemClick = (pivotItem?: PivotItem) => {
    if (!pivotItem) {
      return;
    }
    handleLinkClick(pivotItem?.props.itemKey);
  };

  return (
    <div css={styles.container}>
      <div css={styles.logoContainer}>
        <FontIcon aria-label="Compass" iconName="CompassNW" css={styles.icon} />
        <div css={styles.logo}>Abbbs</div>
      </div>
      <Pivot
        aria-label="Large Link Size Pivot Example"
        linkSize="large"
        headersOnly={true}
        selectedKey={selectedKey}
        onLinkClick={handlePivotItemClick}
        css={styles.desktopNav}
      >
        {navItems.map((item) => (
          <PivotItem
            key={item.key}
            headerText={item.label}
            itemKey={item.key}
          />
        ))}
      </Pivot>

      <IconButton
        iconProps={{
          iconName: "GlobalNavButton",
          className: mergeStyles({
            fontSize: "30px",
          }),
        }}
        title="Navigation"
        ariaLabel="Navigation"
        menuProps={menuProps}
        css={styles.mobileNav}
      />
    </div>
  );
};
