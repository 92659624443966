/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PageContent } from "../../components/PageContent";
import { thunkGetWorkouts } from "../../redux/workouts/workouts-actions";
import {
  selectIsLoadingWorkouts,
  selectWorkouts,
} from "../../redux/workouts/workouts-selectors";
import { mediaMaxWidth } from "../../styles";
import { WorkoutSelector } from "./WorkoutSelector";

export const SelectWorkoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const availableWorkouts = useSelector(selectWorkouts);
  const isLoadingWorkouts = useSelector(selectIsLoadingWorkouts);
  const history = useHistory();

  useEffect(() => {
    dispatch(thunkGetWorkouts());
  }, [dispatch]);

  const styles = {
    container: css`
      margin: 30px auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
      align-items: center;
      width: 500px;
      ${mediaMaxWidth(500)} {
        width: 100%;
      }
    `,
  };

  const handleSelectWorkoutId = (id: number) => {
    history.push(`/run-workout/${id}`);
  };

  return (
    <PageContent
      title={"Select workout"}
      subTitle="To start training, select the exercise below"
    >
      <div css={styles.container}>
        <WorkoutSelector
          workouts={Object.values(availableWorkouts)}
          isLoading={isLoadingWorkouts}
          onWorkoutIdSelected={handleSelectWorkoutId}
        />
      </div>
    </PageContent>
  );
};
