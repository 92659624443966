import { css } from "@emotion/react/macro";

export const textStyle = {
  h1: css`
    text-align: center;
    font-size: 24px;
    font-weight: normal;
    padding: 0;
    margin: 0;
  `,

  h2: css`
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  `,

  large: css`
    font-size: 16px;
    padding: 0;
    margin: 0;
  `,

  bold: css`
    font-weight: bold;
  `,

  uppercase: css`
    text-transform: uppercase;
  `,
};

export const colors = {
  lightGrey: "#cccccc",
  charcoal: "#264653ff",
  persianGreen: "#2a9d8fff",
  orangeYellowCrayola: "#e9c46aff",
  sandyBrown: "#f4a261ff",
  burntSienna: "#e76f51ff",
};

export const resetBtnStyles = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const mediaMaxWidth = (maxWidth: number) => {
  return `@media only screen and (max-width: ${maxWidth}px)`;
};
