/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { thunkLogin } from "../../redux/auth/auth-actions";
import { ILoginCredentials } from "../../redux/auth/auth-types";
import { LoginForm } from "./LoginForm";

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const styles = {
    container: css`
      width: 100%;
      max-width: 1200px;
      margin: 50px auto;
    `,
  };

  const handleLogin = async (data: ILoginCredentials) => {
    await dispatch(thunkLogin(data));
    history.push("/admin");
  };

  return (
    <div css={styles.container}>
      <LoginForm onSubmit={handleLogin} />
    </div>
  );
};
