/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { Toggle } from "@fluentui/react";
import { Formik } from "formik";
import { FormContainer } from "../../components/form/FormContainer";
import { TextInput } from "../../components/form/TextInput";
import { INewWorkout } from "../../redux/workouts/workouts-types";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { textStyle } from "../../styles";

const validationSchema: SchemaOf<INewWorkout> = yup
  .object({
    name: yup.string().defined(),
    description: yup.string().defined(),
    public: yup.boolean().defined(),
    sets: yup.array().defined(),
  })
  .defined();

interface IWorkoutFormProps {
  onSubmit: (data: INewWorkout) => void;
  onCancel: () => void;
}

export const WorkoutForm: React.FC<IWorkoutFormProps> = ({
  onSubmit,
  onCancel,
}) => {
  const styles = {
    container: css`
      padding: 30px;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    `,
  };

  return (
    <div css={styles.container}>
      <h1 css={textStyle.h1}>Create a new workout</h1>
      <Formik
        initialValues={{
          name: "",
          description: "",
          unit: "seconds",
          type: undefined,
          public: false,
          sets: [],
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <FormContainer {...props} onCancel={onCancel}>
            <TextInput
              label="Name"
              placeholder="Name of the workout"
              name="name"
            />
            <TextInput
              label="Description"
              placeholder="Description of the workout"
              name="description"
            />
            <Toggle
              label="Is public"
              defaultChecked={props.values.public}
              onText="Public"
              offText="Private"
              onChange={(_, checked) => {
                return props.setFieldValue("public", checked);
              }}
            />
          </FormContainer>
        )}
      </Formik>
    </div>
  );
};
