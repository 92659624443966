/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { DefaultButton, Modal } from "@fluentui/react";
import { useState } from "react";
import { INewWorkout } from "../../redux/workouts/workouts-types";
import { WorkoutForm } from "./WorkoutForm";

interface IWorkoutModalProps {
  onSubmit: (data: INewWorkout) => void;
}

export const WorkoutModal: React.FC<IWorkoutModalProps> = ({ onSubmit }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const styles = {
    container: css`
      display: flex;
      align-items: center;
      justify-content: end;
      flex: 1;
    `,
  };

  return (
    <div css={styles.container}>
      <DefaultButton
        text="Add new workout"
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={isModalOpen}
        onDismiss={() => setModalOpen(false)}
        isBlocking={false}
        css={css`
          min-width: 500px;
        `}
        styles={{
          main: {
            minWidth: "500px",
          },
        }}
      >
        <WorkoutForm
          onSubmit={(data) => {
            setModalOpen(false);
            onSubmit(data);
          }}
          onCancel={() => setModalOpen(false)}
        />
      </Modal>
    </div>
  );
};
