import { ExerciseType, WorkoutId } from "../workouts/workouts-types";

export type WorkoutItemId = number;

export interface IWorkoutSet {
  id: WorkoutItemId;
  name: string;
  description?: string;
  durationInSeconds: number;
  type: ExerciseType;
  imgSrc: string;
}

export type ICurrentWorkoutSet = IWorkoutSet & {
  startSeconds: number;
  endSenconds: number;
  secondsLeft: number;
  progressPercentage: number;
};

export enum RunningWorkoutStatus {
  Initial = "initial",
  Running = "running",
  Paused = "paused",
  Finished = "finished",
}

export interface IRunninWorkoutState {
  currentWorkoutId?: WorkoutId;
  status: RunningWorkoutStatus;
  startTime: string | undefined;
  intervalId: NodeJS.Timeout | undefined;
  currentWorkoutItemId: WorkoutItemId | undefined;
  workoutSetDurationInSeconds: number | undefined;
}

export interface IRunningWorkoutProgress {
  currentWorkoutSetIndex: number;
  workoutSetsCount: number;
  workoutStatus: RunningWorkoutStatus;
  nextWorkoutSet:
    | (IWorkoutSet & {
        startSeconds: number;
        endSenconds: number;
      })
    | undefined;
}
