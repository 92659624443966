import { reducerWithInitialState } from "typescript-fsa-reducers";
import { getExercises } from "./exercise-actions";
import { IExercisesState } from "./exercise-types";

const initialState: IExercisesState = {
  exercises: {
    activeRequests: 0,
    data: undefined,
  },
  createExercise: {
    activeRequests: 0,
  },
  deleteExercise: {
    activeRequests: 0,
  },
  updateExercise: {
    activeRequests: 0,
  },
};

const reduceActiveRequest = (activeRequests: number): number =>
  activeRequests > 0 ? activeRequests - 1 : 0;

export const exercisesReducer = reducerWithInitialState(initialState)
  .case(getExercises.started, (state) => ({
    ...state,
    exercises: {
      data: undefined,
      activeRequests: state.exercises.activeRequests + 1,
    },
  }))
  .case(getExercises.done, (state, { result }) => ({
    ...state,
    exercises: {
      data: result,
      activeRequests: reduceActiveRequest(state.exercises.activeRequests),
    },
  }))
  .case(getExercises.failed, (state) => ({
    ...state,
    exercises: {
      data: undefined,
      activeRequests: reduceActiveRequest(state.exercises.activeRequests),
    },
  }));
