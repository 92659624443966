import { INewWorkout, WorkoutId } from "../redux/workouts/workouts-types";
import { del, get, post, put } from "./fetch";

export const requestGetRoutines = () => get("/routines");

export const requestGetRoutine = (id: number) => get(`/routines/${id}`);

export const requestCreateRoutine = (data: INewWorkout) =>
  post("/routines", data);

export const requestUpdateRoutine = (id: WorkoutId, data: INewWorkout) =>
  put(`/routines/${id}`, data);

export const requestDeleteRoutine = (id: WorkoutId) => del(`routines/${id}`);
