/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { ReactNode } from "react";

export const LabelAndValue: React.FC<{
  label: string;
  value: ReactNode;
  isFlexEnd?: boolean;
}> = ({ label, value, isFlexEnd = false }) => {
  const styles = {
    container: css`
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: ${isFlexEnd ? "flex-end" : "flex-start"};
      width: fit-content;
    `,
    label: css`
      font-size: 10px;
      text-transform: uppercase;
      font-weight: bold;
    `,
    value: css`
      font-size: 12px;
    `,
  };
  return (
    <dl css={styles.container}>
      <dt css={styles.label}>{label}</dt>
      <dt css={styles.value}>{value}</dt>
    </dl>
  );
};
