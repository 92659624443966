/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { DefaultButton, Modal } from "@fluentui/react";
import { useState } from "react";
import { INewExercise } from "../../redux/exercises/exercise-types";
import { ExerciseForm } from "./ExerciseForm";

interface IExerciseModalProps {
  onSubmit: (data: INewExercise) => void;
}

export const ExerciseModal: React.FC<IExerciseModalProps> = ({ onSubmit }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const styles = {
    container: css`
      display: flex;
      align-items: center;
      justify-content: end;
      flex: 1;
    `,
  };

  return (
    <div css={styles.container}>
      <DefaultButton
        text="Add new exercise"
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={isModalOpen}
        onDismiss={() => setModalOpen(false)}
        isBlocking={false}
        css={css`
          min-width: 500px;
        `}
        styles={{
          main: {
            minWidth: "500px",
          },
        }}
      >
        <ExerciseForm
          onSubmit={(data) => {
            setModalOpen(false);
            onSubmit(data);
          }}
          onCancel={() => setModalOpen(false)}
        />
      </Modal>
    </div>
  );
};
