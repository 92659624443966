/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { Spinner } from "@fluentui/react";
import { formatDuration } from "date-fns";
import {
  IWorkout,
  Level,
  LevelColors,
  WorkoutId,
  ExerciseType,
} from "../../redux/workouts/workouts-types";
import { colors, resetBtnStyles } from "../../styles";
import { LabelAndValue } from "../../components/LabelAndValue";

import { animated, useSpring } from "@react-spring/web";
import { lighten } from "polished";

const WorkoutLevel: React.FC<{ level: Level }> = ({ level }) => {
  const styles = {
    container: css`
      display: flex;
      gap: 5px;
      align-items: center;
    `,
    point: css`
      background-color: ${LevelColors[level]};
      width: 10px;
      height: 10px;
      border-radius: 50%;
    `,
  };
  return (
    <div css={styles.container}>
      <div css={styles.point} />
      {level}
    </div>
  );
};

const WorkoutButton: React.FC<{
  workout: IWorkout;
  onWorkoutIdSelected: (id: number) => void;
}> = ({ workout, onWorkoutIdSelected }) => {
  const [props, api] = useSpring(() => ({
    scale: 1,
    backgroundColor: "#fff",
    transition: "transform 200ms",
  }));

  const styles = {
    workoutButton: css`
      ${resetBtnStyles};

      display: flex;
      gap: 10px;
      flex-direction: column;

      border: 1px solid black;
      border-radius: 5px;
      padding: 20px;
      box-shadow: 1px 2px 6px #888888;
    `,
    workoutName: css`
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
      color: ${LevelColors[workout.level]};
      text-decoration-color: ${LevelColors[workout.level]};
    `,
    workoutDescription: css`
      font-size: 11px;
    `,
    workoutLevel: css`
      font-size: 10px;
      text-transform: uppercase;
    `,
  };

  const workoutSetsInTotal =
    workout.sets?.filter((set) => set.type === ExerciseType.Abs).length || 0;

  const durationInSeconds =
    workout.sets
      ?.map((set) => set.durationInSeconds)
      ?.reduce((acc, sum) => acc + sum, 0) || 0;

  const restInSeconds =
    workout.sets
      ?.filter(
        (set) =>
          set.type === ExerciseType.Rest ||
          set.type === ExerciseType.Countdown
      )
      ?.map((set) => set.durationInSeconds)
      ?.reduce((acc, sum) => acc + sum, 0) || 0;

  const formatSeconds = (seconds: number) => formatDuration({ seconds });

  return (
    <animated.button
      style={props}
      onMouseEnter={() =>
        api.set({
          scale: 1.05,
          backgroundColor: lighten(0.7, colors.charcoal),
        })
      }
      onMouseLeave={() => api.set({ scale: 1, backgroundColor: "#fff" })}
      css={styles.workoutButton}
      key={workout.id}
      onClick={() => {
        onWorkoutIdSelected(workout.id);
      }}
    >
      <div css={styles.workoutName}>{workout.name}</div>
      <div css={styles.workoutDescription}>{workout.description}</div>
      <LabelAndValue label={"Sets"} value={workoutSetsInTotal} />
      <LabelAndValue
        label={"Level"}
        value={<WorkoutLevel level={workout.level} />}
      />
      <LabelAndValue
        label={"Duration"}
        value={formatSeconds(durationInSeconds)}
      />
      <LabelAndValue label={"Rest"} value={formatSeconds(restInSeconds)} />
      <LabelAndValue label={"Sets"} value={workoutSetsInTotal} />
    </animated.button>
  );
};

interface IProps {
  workouts?: IWorkout[];
  isLoading: boolean;
  onWorkoutIdSelected: (id: WorkoutId) => void;
}

export const WorkoutSelector: React.FC<IProps> = ({
  workouts,
  isLoading,
  onWorkoutIdSelected,
}) => {
  const styles = {
    container: css`
      max-width: 900px;
    `,
    grid: css`
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      background-color: #fff;
      color: #444;
    `,
  };

  return (
    <div css={styles.container}>
      {isLoading ? (
        <Spinner label="Just wait..." />
      ) : (
        <div css={styles.grid}>
          {workouts?.map((workout) => (
            <WorkoutButton
              key={workout.id}
              workout={workout}
              onWorkoutIdSelected={onWorkoutIdSelected}
            />
          ))}
        </div>
      )}
    </div>
  );
};
