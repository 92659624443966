import { ExerciseId, INewExercise } from "../redux/exercises/exercise-types";
import { del, get, post, put } from "./fetch";

export const requestGetExercises = () => get("/exercises");

export const requestCreateExercise = (exercise: INewExercise) =>
  post("/exercises", exercise);

export const requestUpdateExercise = (
  exerciseId: ExerciseId,
  exercise: INewExercise
) => put(`/exercises/${exerciseId}`, exercise);

export const requestDeleteExercise = (exerciseId: ExerciseId) =>
  del(`/exercises/${exerciseId}`);
