/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import { textStyle } from "../styles";

const styles = {
  container: css`
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  content: css`
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
  `,
  titles: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  `,
};

export const PageContent: React.FC<{
  children: React.ReactNode;
  title?: string;
  subTitle?: string;
}> = ({ children, title, subTitle: subtitle }) => (
  <div css={styles.container}>
    <div css={styles.content}>
      <div css={styles.titles}>
        {!!title && <h1 css={textStyle.h1}>{title}</h1>}
        {!!subtitle && <h2 css={textStyle.h2}>{subtitle}</h2>}
      </div>
      {children}
    </div>
  </div>
);
