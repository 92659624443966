/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import {
  CheckboxVisibility,
  DetailsList,
  DetailsListLayoutMode,
  Spinner,
} from "@fluentui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { thunkGetWorkouts } from "../../redux/workouts/workouts-actions";
import {
  selectIsLoadingWorkouts,
  selectWorkouts,
} from "../../redux/workouts/workouts-selectors";

export const WorkoutList: React.FC = () => {
  const dispatch = useDispatch();
  const workouts = useSelector(selectWorkouts);
  const isLoadingWorkouts = useSelector(selectIsLoadingWorkouts);

  console.log({ workouts });

  useEffect(() => {
    dispatch(thunkGetWorkouts());
  }, [dispatch]);

  const styles = {
    container: css`
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    `,
  };

  const columns = [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  return (
    <div css={styles.container}>
      {isLoadingWorkouts && <Spinner />}
      {workouts && (
        <DetailsList
          items={Object.values(workouts)}
          columns={columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkboxVisibility={CheckboxVisibility.hidden}
        />
      )}
    </div>
  );
};
