import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
} from "react-router-dom";
import { Navigation } from "./components/Navigation";
import { AboutPage } from "./pages/AboutPage";
import { AdminPage } from "./pages/admin-page/AdminPage";
import { EditWorkoutPage } from "./pages/edit-workout-page/EditWorkout";
import { LoginPage } from "./pages/login-page/LoginPage";
import { RunWorkoutPage } from "./pages/run-workout-page/RunWorkoutPage";
import { SelectWorkoutPage } from "./pages/select-workout-page/SelectWorkoutPage";
import { selectIsAdmin } from "./redux/auth/auth-selectors";
import { store } from "./redux/store";
initializeIcons();

const AdminRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const isAdmin = useSelector(selectIsAdmin);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div>
          <Navigation />
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <AdminRoute path="/admin/workout/:workoutId">
              <EditWorkoutPage />
            </AdminRoute>
            <AdminRoute path="/admin">
              <AdminPage />
            </AdminRoute>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route
              path="/run-workout/:workoutId"
              render={({
                match,
              }: RouteComponentProps<{
                workoutId: string;
              }>) => (
                <RunWorkoutPage
                  workoutId={parseInt(match.params.workoutId, 10)}
                />
              )}
            />
            <Route path="/select-workout">
              <SelectWorkoutPage />
            </Route>
            <Route path="/">
              <Redirect to="/select-workout" />
            </Route>
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
