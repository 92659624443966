/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { DefaultButton, IIconProps } from "@fluentui/react";
import { RunningWorkoutStatus } from "../../redux/running-workout/running-workout-types";

interface IProps {
  workoutStatus: RunningWorkoutStatus;
  onStopWorkoutBtnClick: () => void;
  onStartWorkoutBtnClick: () => void;
  onPauseWorkoutBtnClick: () => void;
  onSkipWorkoutBtnClick: () => void;
}

export const WorkoutActionButtons: React.FC<IProps> = ({
  workoutStatus,
  onStopWorkoutBtnClick,
  onStartWorkoutBtnClick,
  onPauseWorkoutBtnClick,
  onSkipWorkoutBtnClick,
}) => {
  const styles = {
    container: css`
      display: flex;
      justify-content: center;
      gap: 10px;
    `,
  };

  const getStartButtonContent = (): {
    text: string;
    icon: IIconProps;
    onClick: () => void;
  } => {
    switch (workoutStatus) {
      case RunningWorkoutStatus.Finished:
      case RunningWorkoutStatus.Initial:
        return {
          text: "Start workout",
          icon: { iconName: "play" },
          onClick: () => onStartWorkoutBtnClick(),
        };
      case RunningWorkoutStatus.Paused:
        return {
          text: "Continue",
          icon: { iconName: "play" },
          onClick: () => onPauseWorkoutBtnClick(),
        };
      case RunningWorkoutStatus.Running:
        return {
          text: "Pause",
          icon: { iconName: "pause" },
          onClick: () => onPauseWorkoutBtnClick(),
        };
    }
  };

  const startButtonContent = getStartButtonContent();

  return (
    <div css={styles.container}>
      <DefaultButton
        text={startButtonContent.text}
        onClick={startButtonContent.onClick}
        iconProps={startButtonContent.icon}
        allowDisabledFocus
      />
      {workoutStatus === RunningWorkoutStatus.Running && (
        <DefaultButton
          text={"Skip"}
          onClick={onSkipWorkoutBtnClick}
          iconProps={{ iconName: "redo" }}
          allowDisabledFocus
        />
      )}
      <DefaultButton
        text="Reset"
        onClick={onStopWorkoutBtnClick}
        allowDisabledFocus
        iconProps={{ iconName: "refresh" }}
        disabled={workoutStatus === RunningWorkoutStatus.Initial}
      />
    </div>
  );
};
