import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import {
  requestCreateExercise,
  requestDeleteExercise,
  requestGetExercises,
  requestUpdateExercise,
} from "../../api/exercise-api";
import { AppState } from "../store";
import { actionCreator } from "../util";
import { ExerciseId, IExercise, INewExercise } from "./exercise-types";

export const getExercises = actionCreator.async<undefined, IExercise[], string>(
  "GET_EXERCISES"
);
export const createExercise = actionCreator.async<INewExercise, void, string>(
  "CREATE_EXERCISE"
);
export const deleteExercise = actionCreator.async<number, void, string>(
  "DELETE_EXERCISE"
);
export const updateExercise = actionCreator.async<
  { id: ExerciseId; data: INewExercise },
  void,
  string
>("UPDATE_EXERCISE");

export const thunkGetExercises =
  (): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(getExercises.started(undefined));
      const data = await requestGetExercises();
      dispatch(getExercises.done({ result: data }));
    } catch (e: any) {
      dispatch(getExercises.failed({ error: e.toString() }));
    }
  };

export const thunkUpdateExercise =
  (params: {
    id: ExerciseId;
    data: INewExercise;
  }): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(updateExercise.started(params));
      await requestUpdateExercise(params.id, params.data);
      dispatch(updateExercise.done({ params }));
      dispatch(thunkGetExercises());
    } catch (e: any) {
      dispatch(updateExercise.failed({ params, error: e.toString() }));
    }
  };

export const thunkDeleteExercise =
  (id: ExerciseId): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(deleteExercise.started(id));
      await requestDeleteExercise(id);
      dispatch(deleteExercise.done({ params: id }));
      dispatch(thunkGetExercises());
    } catch (e: any) {
      console.log(e);
      dispatch(deleteExercise.failed({ params: id, error: e.toString() }));
    }
  };

export const thunkCreateExercise =
  (params: INewExercise): ThunkAction<void, AppState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(createExercise.started(params));
      await requestCreateExercise(params);
      dispatch(createExercise.done({ params }));
      dispatch(thunkGetExercises());
    } catch (e: any) {
      dispatch(createExercise.failed({ params, error: e.toString() }));
    }
  };
