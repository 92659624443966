/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectCurrentWorkout } from "../../redux/workouts/workouts-selectors";
import {
  setCurrentWorkout,
  thunkGetworkout,
} from "../../redux/workouts/workouts-actions";

export const EditWorkoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const { workoutId } = useParams<{ workoutId: string }>();
  const workout = useSelector(selectCurrentWorkout);
  const workoutIdParsed = parseInt(workoutId, 10);
  console.log({ workoutIdParsed });

  useEffect(() => {
    if (workout?.id === workoutIdParsed) {
      return;
    }
    dispatch(thunkGetworkout(workoutIdParsed));
    dispatch(setCurrentWorkout(workoutIdParsed));
    return () => {
      dispatch(setCurrentWorkout(undefined));
    };
  }, [dispatch, workout?.id, workoutIdParsed]);

  const styles = {
    container: css``,
  };

  return <div css={styles.container}>Moi {workout?.name}</div>;
};
